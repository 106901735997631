<template>
  <v-container fluid>
    <v-card
      flat
      outlined
      class="pa-sm-4 wraperx imso-toolbar"
      v-if="dataLoading"
    >
      <v-skeleton-loader type="image"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx imso-toolbar" v-else>
      <v-card-title class="primary--text">
        OPEnE Vehicles - Private Use Report
        <v-spacer></v-spacer>
      </v-card-title>
      <v-form ref="form">
        <v-card-title class="primary--text align-start">
          <v-col style="min-width: 150px">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              v-model="menu1"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  filled
                  :rules="[rules.required]"
                  flat
                  outlined
                  label="From (Date)"
                  v-model="startDate"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                color="primary"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col style="min-width: 150px">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              v-model="menu2"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :rules="[rules.required]"
                  filled
                  flat
                  outlined
                  label="To (Date)"
                  v-model="endDate"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                color="primary"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col style="min-width: 200px">
            <v-autocomplete
              filled
              :rules="[rules.required]"
              flat
              v-model="vehicleUuid"
              :items="vehicles"
              item-text="vehicle_no"
              item-value="uuid"
              label="Vehicle"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col style="min-width: 300px">
            <v-autocomplete
              filled
              flat
              v-model="staffUuids"
              :items="staff"
              item-text="name"
              item-value="uuid"
              label="Staff"
              outlined
              dense
              multiple
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col style="min-width: 150px">
            <v-text-field
              filled
              :rules="[rules.required]"
              flat
              type="number"
              v-model="costPerKm"
              label="Cost per km"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              block
              height="40"
              depressed
              color="primary"
              :loading="loading"
              @click="fetchLogs()"
            >
              Generate Report
            </v-btn>
          </v-col>
        </v-card-title>
      </v-form>
    </v-card>
    <v-card
      flat
      outlined
      class="pa-sm-4 mt-6 wraperx"
      id="print-only"
      v-if="records.length > 0"
    >
      <v-card-title>
        <v-sheet color="transparent">
          <v-sheet color="transparent">
            OPEnE Vehicles - Private Use Report
          </v-sheet>
          <v-sheet color="transparent text-caption">
            Vehicle No : {{ vehicle.vehicle_no }}
          </v-sheet>
          <v-sheet color="transparent text-caption">
            Vehicle Class : {{ vehicle.class }}
          </v-sheet>
          <v-sheet color="transparent text-caption">
            Date : {{ startDate + " to " + endDate }}
          </v-sheet>
        </v-sheet>
        <v-spacer> </v-spacer>
        <v-img
          max-width="250"
          contain
          alt="IMSO"
          :src="require('@/assets/logo.png')"
          class="ma-auto"
        />
      </v-card-title>

      <v-card-text class="tableText--text">
        <table class="imso-table" id="imso-table-1" v-if="records.length > 0">
          <tr>
            <th rowspan="1">S.No</th>
            <th rowspan="1">Vehicle No</th>
            <th rowspan="1">Start km</th>
            <th rowspan="1">End km</th>
            <th rowspan="1">Total km</th>
            <th rowspan="1">Private Use</th>
            <th rowspan="1">Others</th>
            <th rowspan="1">Staff</th>
          </tr>

          <tr v-for="(record, i) in records" :key="i">
            <td>
              {{ i + 1 }}
            </td>
            <td>
              {{ record.vehicle_no }}
            </td>
            <td class="number-td">
              {{ record.start_km }}
            </td>
            <td class="number-td">
              {{ record.end_km }}
            </td>
            <td class="number-td">
              {{ record.total_km }}
            </td>
            <td class="number-td">
              {{ record.project_total_km }}
            </td>
            <td class="number-td">
              {{ record.others_km }}
            </td>
            <td>
              {{ record.staff_name }}
            </td>
          </tr>
          <tr>
            <th colspan="4">Total</th>
            <th class="number-td">{{ totalKm }}</th>
            <th class="number-td total-td">{{ totaProjectKm }}</th>
            <th class="number-td total-td">{{ totalOthersKm }}</th>
            <th></th>
          </tr>
          <tr>
            <th colspan="5">Running Cost ({{ costPerKm }} LKR per km)</th>
            <th class="number-td full-total-td">
              {{ totaProjectKm * costPerKm }}
            </th>
            <th class="total-td"></th>
            <th></th>
          </tr>
        </table>
        <v-sheet
          color="transparent"
          outlined
          class="mt-12 pt-12 text-center text-caption ml-auto"
          max-width="300"
        >
          <v-divider></v-divider>
          Approved By
        </v-sheet>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="text-caption text-right text--secondary pb-0 my-0">
        <v-spacer></v-spacer>
        This is a system generated report. Generated at :
        {{ new Date() }}. imso-app.openesrilanka.org
      </v-card-actions>
    </v-card>
    <v-card
      flat
      outlined
      class="pa-sm-4 mt-6 wraperx imso-toolbar d-flex justify-end align-center"
      v-if="records.length > 0"
    >
      <v-sheet color="transparent text-caption text--secondary">
        Please select the light theme before printing.
      </v-sheet>

      <v-btn color="primary" dark depressed @click="downloadPdf" class="ms-4"
        >Print</v-btn
      >
      <v-btn color="success" depressed @click="downloadTableAsCSV" class="ms-4"
        >Download CSV</v-btn
      >
    </v-card>
    <v-card
      flat
      outlined
      class="pa-sm-4 mt-6 wraperx imso-toolbar d-flex justify-center align-center"
      v-else-if="vehicle"
    >
      No data found for the selected filters.
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { fetchAllVehicleRunning } from "@/services/logsService";
import {
  fetchAndStoreStaffData,
  fetchAndStoreVehiclesData,
  fetchAndStoreProjectsData,
} from "@/services/preloadDataService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    //
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    menu1: false,
    menu2: false,
    dataLoading: false,
    loading: false,
    costPerKm: 0,
    items: [],
    page: 1,
    limit: -1,
    startDate: "",
    endDate: "",
    projectUuid: null,
    vehicleUuid: null,
    staffUuids: [],
    vehicle: null,
    vehicles: [],
    projects: [],
    staff: [],
    records: [],
    ledgerRecords: [],
    totalKm: 0,
    totaProjectKm: 0,
    totalOthersKm: 0,
    logs: [],
  }),
  async created() {
    if (
      this.$_checkPermission(
        this.auth.permissions,
        "Manage Vehicle Running Logs",
        "Read All"
      ) &&
      this.$_checkPermission(this.auth.permissions, "Reports", "Generate")
    ) {
      this.dataLoading = true;
      this.vehicles = await fetchAndStoreVehiclesData();
      this.projects = await fetchAndStoreProjectsData();
      this.staff = await fetchAndStoreStaffData();
      this.staff.forEach((person) => {
        person["name"] = person.first_name + " " + person.last_name;
      });
      this.vehicles = this.vehicles.filter((obj) => obj.category == "OPEnE");
      this.endDate = this.formatDate(new Date());
      this.startDate = this.formatDate(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      );
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    async fetchLogs() {
      if (this.$refs.form.validate()) {
        this.records = [];
        this.ledgerRecords = [];
        this.totalKm = [];
        this.totaProjectKm = [];
        this.totalOthersKm = [];
        this.items = [];
        this.loading = true;
        this.items = await fetchAllVehicleRunning(
          this.page,
          this.limit,
          this.startDate ? this.startDate : "",
          this.endDate ? this.endDate : "",
          JSON.stringify([this.vehicleUuid]),
          JSON.stringify(this.staffUuids),
          JSON.stringify([
            this.projects.find((obj) => obj.code === "PRIVATE USE").uuid,
          ]),
          JSON.stringify([]),
        );

        this.changeVehicle();
        this.items = this.items.data;

        [
          this.records,
          this.ledgerRecords,
          this.totalKm,
          this.totaProjectKm,
          this.totalOthersKm,
        ] = await this.generateReport(this.items);

        this.loading = false;
      }
    },
    async generateReport(items) {
      const itemsGrpByVeicle = this.$_.groupBy(items, "vehicle_uuid");
      let records = [];
      let ledgerRecords = [];

      let itemsLedgerRecord = [];
      this.$_.forEach(itemsGrpByVeicle, (itemGrpByVeicle) => {
        const start_km = itemGrpByVeicle[0].vehicle_km_data.start_km;
        const end_km = itemGrpByVeicle[0].vehicle_km_data.end_km;

        const itemsGrpByDriver = this.$_.groupBy(
          itemGrpByVeicle,
          "driver_uuid"
        );
        this.$_.forEach(itemsGrpByDriver, (itemGrpByDriver) => {
          records.push({
            vehicle_no: itemGrpByDriver[0].vehicles.vehicle_no,
            start_km: start_km,
            end_km: end_km,
            total_km: end_km - start_km,
            project_total_km: this.$_.sumBy(itemGrpByDriver, "total_km"),
            others_km:
              end_km - start_km - this.$_.sumBy(itemGrpByDriver, "total_km"),
            staff_name:
              itemGrpByDriver[0].staff.first_name +
              " " +
              itemGrpByDriver[0].staff.last_name,
          });
        });
      });

      const itemsLedgerRecordGrpByCode = this.$_.groupBy(
        itemsLedgerRecord,
        "ledger_code"
      );
      this.$_.forEach(itemsLedgerRecordGrpByCode, (qvalue, key) => {
        ledgerRecords.push({
          ledger_code: key,
          total_km: this.$_.sumBy(qvalue, "ledger_total_km"),
        });
      });

      const totalKm = this.$_.sumBy(records, "total_km");
      const totaProjectKm = this.$_.sumBy(records, "project_total_km");
      const totalOthersKm = this.$_.sumBy(records, "others_km");

      return [records, ledgerRecords, totalKm, totaProjectKm, totalOthersKm];
    },
    changeVehicle() {
      this.vehicle = this.vehicles.find((obj) => obj.uuid === this.vehicleUuid);
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, add 1 and pad with 0
      const day = String(date.getDate()).padStart(2, "0"); // Pad day with 0 if necessary
      return `${year}-${month}-${day}`;
    },
    downloadPdf() {
      window.print();
    },
    downloadTableAsCSV() {
      const tableIds = ["imso-table-1"];
      const filename =
        "OPEnE Vehicles - Private Use Report - " + this.vehicle.vehicle_no;

      const csv = [];
      tableIds.forEach((tableId) => {
        const table = document.getElementById(tableId);
        if (table) {
          const rows = table.querySelectorAll("tr");

          // Track the grid position for rowspan and colspan cells
          const cellGrid = [];
          let rowIndex = 0;

          // Loop through each row of the table
          for (rowIndex; rowIndex < rows.length; rowIndex++) {
            const row = rows[rowIndex];
            const cells = row.querySelectorAll("th, td");
            const rowData = [];
            let columnIndex = 0;

            // Ensure grid is initialized for this row
            if (!cellGrid[rowIndex]) {
              cellGrid[rowIndex] = [];
            }

            // Process each cell in the current row
            for (let cell of cells) {
              // Skip over grid positions that are already occupied by a rowspan/colspan
              while (cellGrid[rowIndex][columnIndex]) {
                columnIndex++;
              }

              // Get cell content and replace commas to avoid CSV issues
              let cellContent = cell.textContent.replace(/[,|\n]/g, " ");

              // Add cell content to rowData
              rowData[columnIndex] = cellContent;

              // Handle colspan
              let colspan = cell.getAttribute("colspan") || 1;
              colspan = parseInt(colspan);

              // Handle rowspan
              let rowspan = cell.getAttribute("rowspan") || 1;
              rowspan = parseInt(rowspan);

              // Mark the grid for this cell's colspan and rowspan
              for (let r = 0; r < rowspan; r++) {
                if (!cellGrid[rowIndex + r]) {
                  cellGrid[rowIndex + r] = [];
                }
                for (let c = 0; c < colspan; c++) {
                  cellGrid[rowIndex + r][columnIndex + c] = true;
                }
              }

              // Move the column index forward by the colspan amount
              columnIndex += colspan;
            }

            // Add row data to CSV, ensuring commas separate the columns
            csv.push(rowData.join(","));
          }
        }
      });

      // Join all rows with a newline character to form the CSV string
      const csvContent = csv.join("\n");

      // Create a temporary download link and trigger the download
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link after download
      document.body.removeChild(link);
    },
  },
};
</script>
